const header = {
  homepage: "https://sidd.tech",
  title: "SN.",
};

const about = {
  name: "Siddharth Nema",
  role: "Aspiring Developer",
  // description: "Add Description About Myself",
  // resume: "https://drive.google.com/file/d/1IBcVxj5yjQJnnghfX3AZdXBh9iF6RhKE/view?usp=sharing",
  resume: "https://drive.google.com/file/d/1ZXfM4Axw3euLWW0ttJGEHtoCvs_6au7W/view?usp=sharing",
  social: {
    // linkedin: "https://linkedin.com",
    github: "https://github.com/SiddharthN16",
    devpost: "https://devpost.com/SiddharthN16",
  },
};

const projects = [
  // Three projects max for front -> Add modal window for rest
  {
    name: "Guber",
    description:
      "Guber is a web application to help initiate and promote garbage cleanups in surrounding parks and neighborhoods.",
    stack: ["React", "Firebase", "Maps API", ""],
    sourceCode: "https://github.com/SiddharthN16/Guber",
    livePreview: "https://guber.pages.dev/",
  },
  {
    name: "The Orb",
    description:
      "Winning Hackathon Project for FraserHacks2019. Built a modern, cost-effective, ergonomic, and portable gesture-based multicolor lamp to be used in a variety of situations.",
    stack: ["Arduino", "FastLED", "MPU6050"],
    sourceCode: "https://github.com/SiddharthN16/FraserHacks2019",
    livePreview: "https://devpost.com/software/the-orb",
  },
  {
    name: "MagicMouse",
    description:
      "Submission for Hack the North 2020++. MagicMouse is an easy alternative to a physical mouse using AI to track movements of a hand in the air to move a mouse cursor. ",
    stack: ["Python", "TensorFlow", "OpenCV"],
    sourceCode: "https://github.com/SiddharthN16/MagicMouse-HTN2020",
    livePreview: "https://devpost.com/software/magic-mouse",
  },
];

const skills = [
  "Python",
  "JavaScript",
  "HTML",
  "CSS",
  "Node.js",
  "Git",
  "React",
  "Bootstrap",
  "OpenCV",
  "Tensorflow",
  "Flask",
  "Firebase",
  "LaTeX",
  "Arduino",
  "Raspberry Pi",
];

const contact = {
  email: "siddharthnema04@gmail.com",
};

export { header, about, projects, skills, contact };
